.ProjectCard_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  .project_image {
    position: relative;
    overflow: hidden;
    border: 0.5px solid rgba(0, 0, 0, 0.479);
    height: 302px;
    img {
      width: 300px;
      height: 100%;
      transition: filter 0.5s ease;
    }
  }
  .project_descption {
    position: absolute;
    bottom: -100%;
    height: 90%;
    left: 0%;
    text-align: center;
    transition: bottom 0.5s ease;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    text-align: center;
    padding: 0px 20px;
    background: rgba(0, 0, 0, 0.95);
    span {
      margin: 5px;
    }
  }
  .project_image:hover img,
  .project_image:active img {
    filter: blur(2px);
  }
  .project_image:hover .project_descption,
  .project_image:active .project_descption {
    bottom: 0%;
  }
  h4 {
    padding: 15px;
  }
  .description {
    color: white;
    margin-bottom: 20px;
  }
  p {
    font-size: 14px;
  }
  hr {
    color: var(--blue);
    margin: 0px;
  }
}
