.icon_col {
  display: flex;
  justify-content: center;
  .icon_box {
    box-shadow: 0 2px 4px 0 var(--blue), 0 3px 10px 0 var(--blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    border-radius: 20px;
    margin: 10px;
    p {
      margin: 0;
      padding: 10px 5px;
    }
    .skill_icons {
      max-width: 50px;
      height: 40px;
      padding: 5px;
      padding-top: 10px;
    }
  }
}
