.JobDescription_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .job_details {
    background-color: black;
    padding: 20px 40px;
    margin-left: 20px;
    .job_title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .job_position {
        margin-right: 10px;
        color: var(--light_blue);
      }
    }
    .point_container {
      display: flex;
      flex-direction: row;
      align-items: first baseline;
      padding: 10px 0px;

      .point_arrow {
        font-size: 15px;
      }
      svg {
        padding-right: 10px;
        color: var(--light_blue);
      }
      p {
        margin: 0;
        width: 100%;
      }
    }
  }
}
