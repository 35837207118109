.AboutMe_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 70px;
  .about_me {
    background-color: black;
    color: white;
    padding: 40px;
    margin: 10px 0px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
  }
  .skills_container {
    padding-top: 70px;
    .skills {
      background-color: black;
      color: white;
      padding: 40px;
      margin: 45px 0px;
    }
  }
}
