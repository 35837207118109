@import "src/breakpoints.scss";

.NavBar_container {
  width: 100%;
  height: 70px;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.99) 0%, #181818b7 99%);
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 1;
  .logo {
    font-size: 2rem;
    color: var(--blue);
    padding: 30px;
    font-weight: bolder;
    .nav_element {
      color: var(--blue);
      text-decoration: none;
    }
    .nav_element:hover {
      cursor: pointer;
    }
  }

  .nav_elements_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: $breakpoint-tablet) {
      .nav_element,
      .btn_container button {
        display: none !important;
      }
      .resume_icon {
        display: block !important;
        color: var(--blue);
        font-size: 30px;
      }
    }
    .resume_btn {
      text-decoration: none;
    }
    .resume_icon {
      display: none;
    }
    .nav_element {
      color: white;
      padding: 25px;
      text-decoration: none;
    }
    .nav_element:hover {
      color: var(--light_blue);
      padding: 25px;
      cursor: pointer;
    }
    .btn_container {
      display: flex;
      padding: 30px;
    }
  }
}
