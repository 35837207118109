@import "src/breakpoints.scss";

.Homepage_container {
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  scroll-behavior: smooth;
  padding-top: 70px;

  @media (max-width: $breakpoint-tablet) {
    #main_cont {
      flex-direction: column !important;
    }
    .headshot_image {
      width: 100% !important;
      margin: 50px 20px !important;
      max-width: 300px;
    }
  }
  @media (max-width: $breakpoint-phone) {
    #main_cont {
      flex-direction: column !important;
    }
    .headshot_image {
      width: 100% !important;
      margin: 50px 20px !important;
      max-width: 225px;
    }
    .intro_container {
      text-align: center;
    }
  }
  .headshot_image {
    width: 300px;
    height: auto;
    border-radius: 50%;
    box-shadow: 12px 0 var(--blue), 24px 0 #023650d0, 36px 0 #02365052;
    margin: 50px;
  }
  .contact_icons {
    display: flex;
    border-radius: 20px;
    font-size: 30px;
    color: var(--blue);
    padding: 10px;
    background-color: black;
    margin: 5px;
  }
  .contact_icons:hover {
    color: var(--light_blue);
    cursor: pointer;
  }
}
