.Contact_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin-bottom: 100px;
  .contact_icons {
    display: flex;
    border-radius: 20px;
    font-size: 40px;
    color: var(--blue);
    padding: 10px;
    background-color: black;
    margin: 5px;
    margin-top: 20px;
  }
  .contact_icons:hover {
    color: var(--light_blue);
    cursor: pointer;
  }
}

.copyRight {
  color: var(--blue);
  text-align: center;
}
